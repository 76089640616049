import AES from 'crypto-js/aes';

import useAuthStore from '@src/store/auth';

import useAuth from '@helper/hooks/useAuth';

import { invoiceAPI, invoiceAdminAPI, publicAPI } from './api';
import { handleErrMessage, handleErrorMessage, handleSuccess } from './shared';

export const getInvoice = async ({ customerAccount, invoiceNumber }, pbrc=false) => {
  try {
    //const { data } = await invoiceAPI().get(`/invoices?customer=${customerAccount}&invoice=${invoiceNumber}`);
    const user = useAuthStore.getState().userAuthState;
    let encryptedQueries = AES.encrypt(
      JSON.stringify({ customerAccount: customerAccount, invoiceNumber: invoiceNumber }),
      'pBzpnEu985ae',
    ).toString();
    if (user?.isLoggedIn) {
      console.log("Logged in")
      let url = `/invapi/cashier/invoices`;
      if(pbrc){
        url = `/pbrc/invapi/cashier/invoices`;
      }
      const { data } = await invoiceAdminAPI().post(url, {
        encryptedQueries: encryptedQueries,
      });

      return data;
    } else {
      console.log("Anon")
      let url = `/invapi/invoices`;
      if(pbrc){
        url = `/pbrc/invapi/invoices`;
      }
      const { data } = await invoiceAPI().post(url, {
        encryptedQueries: encryptedQueries,
      });

      return data;
    }
  } catch (error) {
    handleErrorMessage(error);
    return null;
  }
};

export const postPaymentDetails = async (json, pbrc=false) => {
  try {
    let url = `/invapi/payment`;
    if(pbrc){
      url = `/pbrc/invapi/payment`;
    }
    const { data } = await invoiceAPI().post(url, json);
    return data;
  } catch (error) {
    handleErrorMessage(error);
    return null;
  }
};

export const sendReceiptEmail = async (json, pbrc=false, international=false) => {
  try {
    let url = `/invapi/sendmail-new`;
    if(pbrc){
      url = `/pbrc/invapi/sendmail-new`;
    }
    if(international){
      url = `/intapi/invapi/sendmail-new`
    }
    const { data } = await invoiceAPI().post(url, json);
    return data;
  } catch (error) {
    handleErrMessage('Unable to send mail');
  }
};

export const postGenerateReceipt = async (body, pbrc=false, international=false) => {
  let url = `/invapi/receipt/generate-pdf`;
  if(pbrc){
    url = `/pbrc/invapi/receipt/generate-pdf`;
  }

if(international){
  url = `/intapi/invapi/invapi/receipt/generate-pdf`
}
  try {
    const { data } = await invoiceAPI().post(url, body);
    return data.receiptBuffer;
  } catch (error) {
    try {
      const { data } = await invoiceAPI().post(url, body);
      return data.receiptBuffer;
    } catch (e) {
      handleErrorMessage(e);
    }
  }
};

export const medicalRecordSummaries = async (body) => {
  let url = `/invapi/medicalRecord/generate-pdf`;
  try {
    const { data } = await invoiceAPI().post(url, body);
    return data.receiptBuffer;
  } catch (error) {
    try {
      const { data } = await invoiceAPI().post(url, body);
      return data.receiptBuffer;
    } catch (e) {
      handleErrorMessage(e);
    }
  }
};

export const sendRating = async ({ paymentType, rating, comment, retrievalReferenceNumber }, pbrc=false, international=false) => {
  try {
    let url = `/invapi/rating`;
    if(pbrc){
      url = `/pbrc/invapi/rating`;
    }

    if(international){
      url=`/intapi/invapi/rating`;
    }
    let encryptedQueries = AES.encrypt(
      JSON.stringify({
        paymentType: paymentType,
        rating: rating,
        comment: comment,
        retrievalReferenceNumber: retrievalReferenceNumber,
      }),
      'pBzpnEu985ae',
    ).toString();

    const { data } = await invoiceAPI().post(url, {
      encryptedQueries: encryptedQueries,
    });

    return data;
  } catch (error) {
    handleErrMessage('Unable to submit feedback. Please try again later.');
  }
};

export const getRatingReport = async (dateFrom, dateTo) => {
  try {
    const { data } = await invoiceAdminAPI().post(`/invapi/admin/rating`, {
      dateFrom: dateFrom,
      dateTo: dateTo,
    });
    return data;
  } catch (error) {
    handleErrMessage('Unable to request report. Please try again later.');
  }
};

export async function getReceivableActivities(page, international = false) {
  const user = useAuthStore.getState().userAuthState;
  try {
    if (user?.isLoggedIn) {
      const { data } = await invoiceAdminAPI().post(`/invapi/cashier/recactivities`, null, {
        params: {
          page,
        }
      });

      return data;
    } else {
      let url = `/invapi/recactivities`;

      if(international){
        url = '/intapi/invapi/recactivities'
      }
      const { data } = await invoiceAPI().post(url, null, {
        params: {
          page,
        }
      });
      return data;
    }
  } catch (err) {
    handleErrMessage('Unable to fetch merchant reference data. Please try again later.');

    return err;
  }
}

export async function getCashierReport(params){
  let url = `/invapi/cashier/dailyreport`;
  const header = {
    ["health-entity-code"]: params.healthEntityCode,
    ["creation-date-from"]: params.dateFrom,
    ["creation-date-to"]: params.dateTo
  }

  if(params.username){
    header['stafflink-user'] = params.username
  }

  if(params.receiptNo){
    header['receipt-number'] = params.receiptNo
  }

  if(params.paymentMethod){
    header['payment-method'] = params.paymentMethod
  }
  console.log(header);
  try{
    const { data } = await invoiceAdminAPI('',header).get(url);
    return data;
  }catch(err){
    handleErrMessage('Unable to fetch cashier report. Please try again later.');

    return err;
  }
  
}

export async function getCashierTerminalExceptions(healthEntity = "", cashierName = "") {

  try {
    const header = {}

    let url = "/invapi/cashier/terminal-exceptions"

    if(healthEntity !== ""){
      url += `?healthEntity=${healthEntity}`;
      if(cashierName !== ""){
        url += `&cashierFullName=${cashierName}`
      }
    }

    const { data } = await invoiceAdminAPI('',header).get(url);
    return data;
  } catch (err) {
    handleErrMessage('Unable to fetch exceptions data. Please try again later.');
    return null;
  }
}

export async function putCashierTerminalExceptions(body) {
  try {
    const header = {}
    let url = "/invapi/cashier/terminal-exceptions"
    const { data } = await invoiceAdminAPI('',header).put(url, body);
    return data;
  } catch (err) {
    handleErrMessage('Unable to fetch exceptions data. Please try again later.');
    return err;
  }
}

export const postGenerateCashierPdfReceipt = async (body) => {
  try {
    const { data } = await publicAPI().post(`/invapi/cashier/report/generate-cashier-pdf`, body);
    return data.receiptBuffer;
  } catch (error) {
    try {
      const { data } = await publicAPI().post(`/invapi/cashier/report/generate-cashier-pdf`, body);
      return data.receiptBuffer;
    } catch (e) {
      handleErrorMessage(e);
    }
  }
};

export const postGenerateCashierCsvReceipt = async (body) => {
  try {
    const { data } = await publicAPI().post(`/invapi/cashier/report/generate-cashier-csv`, body);
    return data.receiptBuffer;
  } catch (error) {
    try {
      const { data } = await publicAPI().post(`/invapi/cashier/report/generate-cashier-csv`, body);
      return data.receiptBuffer;
    } catch (e) {
      handleErrorMessage(e);
    }
  }
};
